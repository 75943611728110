import React from "react";

import { graphql } from "gatsby";
import { FormattedDate } from "react-intl";

import { css } from "@emotion/react";
import styled from "@emotion/styled";
import rehypeRaw from "rehype-raw";

import Video from "../../components/shared/Elements/Video";
import Image_ from "../../components/shared/Image";
import JsonLd from "../../components/shared/JsonLd";
import LinkComponent from "../../components/shared/LinkComponent";
import MarkdownBlock from "../../components/shared/Markdown";
import NewHeadline from "../../components/shared/NewHeadline";
import SEO from "../../components/shared/SEO";

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 20px;
    ${({ theme }) => theme.paddings.containerOuterInner.left}
    ${({ theme }) => theme.paddings.containerOuterInner.right}
`;

const Container = styled.div`
    margin-top: 15px;
    ${({ theme }) => css`
        ${theme.breakpoints.from.medium.css`
            margin-top: 0;
        `}
        ${theme.paddings.containerOuterInner.left}
        ${theme.paddings.containerOuterInner.right}

        color: ${theme.colors.blue.toString()};
    `}
`;

const Text = styled(MarkdownBlock)`
    margin: 20px 0;
    font-size: 1rem;

    br {
        display: block;
        margin-top: 20px;
        content: " ";
    }
`;

const BackButton = styled(LinkComponent)`
    margin-bottom: 1em;
`;
const BlogsPage = ({ data: { blog, page } }) => {
    const slug = `blog/${blog.slug}`;
    const url = `https://www.hoyer.de/${slug}`;

    const textStyles = css`
        strong {
            font-weight: 700;
        }
    `;
    const h = (headline) => {
        const Headline = ({ children }) => (
            <NewHeadline is={headline}>{children}</NewHeadline>
        );

        return Headline;
    };
    const components = {
        h2: h(2),
        h3: h(3),
        h4: h(4),
        h5: h(5),
        h6: h(6),
    };

    return (
        <>
            <JsonLd>
                {{
                    "@context": "https://schema.org/",
                    "@id": url,
                    "@type": "Article",
                    url,
                    ...blog,
                    section: undefined,
                    slug: undefined,
                    titleAlignment: undefined,
                    image: blog.image ?? undefined,
                    video: blog.video ?? undefined,
                }}
            </JsonLd>

            <SEO
                lang="de"
                {...{ slug, ...page.meta }}
                templateFields={blog}
                image={blog.image}
            />

            <HeaderContainer>
                <BackButton
                    link={{ text: "Zurück", href: "/blog" }}
                    icon="arrow-left"
                    leftIcon
                    goBack
                />
                <NewHeadline is="1">{blog.title}</NewHeadline>
                {blog?.image?.src && <Image_ {...blog.image} />}
                {blog?.video?.url && (
                    <Video url={blog.video.url} withContainer={false} />
                )}
            </HeaderContainer>
            <Container>
                <FormattedDate
                    value={
                        Number(blog.datePublished)
                            ? Number(blog.datePublished)
                            : blog.datePublished
                    }
                    format="full"
                />

                <Text
                    className={textStyles}
                    rehypePlugins={[rehypeRaw]}
                    components={components}
                >
                    {blog.articleBody}
                </Text>
            </Container>
        </>
    );
};
export default BlogsPage;

export const query = graphql`
    query ($slug: String!) {
        page: pageItemsYaml(templateKey: { eq: "pageItems/blog" }) {
            ...PageItem
        }
        blog: blogsYaml(slug: { eq: $slug }) {
            abstract
            articleBody
            dateCreated
            dateModified
            datePublished
            disabled
            id
            section
            slug
            title
            yamlId
            articleBody
            image {
                alt
                imageObject {
                    caption
                    description
                }
                src {
                    ...ImageSrc
                    childImageSharp {
                        gatsbyImageData(
                            layout: FULL_WIDTH

                            placeholder: BLURRED
                        )
                    }
                }
                title
            }
            video {
                url
                thumbnail {
                    alt
                    imageObject {
                        caption
                        description
                    }
                    src {
                        ...ImageSrc
                        childImageSharp {
                            gatsbyImageData(
                                layout: FULL_WIDTH

                                placeholder: BLURRED
                            )
                        }
                    }
                    title
                }
            }
        }
    }
`;
